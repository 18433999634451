.map {
  position: relative;
  margin: 0;
  min-height: 780px;
  background: #f2f2f2 center top no-repeat url("/assets/img/map_bg.jpg");
  @include breakpoint($mobile) {
    margin: 360px 0 0;
  }
  &__contacts {
    position: absolute;
    top: 274px;
    left: 50%;
    margin-left: -290px;
    width: 580px;
    height: 282px;
    background-color: rgba(255,255,255,.7);
    @include breakpoint($tablet) {
      margin-left: -175px;
      width: 350px;
      height: 420px;
    }
    @include breakpoint($mobile) {
      width: 100%;
      margin: 0;
      left: 0;
      top: -360px;
      height: 360px;
    }
  }
  &__empty-footer {
    min-height: 974px;
  }
}
.contacts {
  &__list {
    margin: 18px 0;
    padding: 0;
    color: #616674;
    list-style: none;
    @include clearfix();
  }
  &__item {
    position: relative;
    float: left;
    margin: 2px 0 25px;
    width: 50%;
    @include breakpoint($tablet) {
      float: none;
      width: 100%;
    }
    @include breakpoint($mobile) {
      margin-bottom: 10px;
    }
  }
  &__svg {
    margin: 0 10px 0 19px;
    width: 45px;
    height: 45px;
    color: $color-text__accent;
  }
  &__title {
    position: absolute;
    top: 15px;
  }
}