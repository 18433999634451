.skills {
  padding: 24px 0 0;
  &__header {
    margin: 0 0 24px;
    font-size: 28px;
    font-weight: $font-weight__medium;
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    @include clearfix();
  }
  &__item {
    float: left;
    margin: 0 27px 27px 0;
    &:last-child {
      margin-right: 0;
    }
  }
  &__circle {
    position: relative;
    width: 110px;
    height: 110px;
    //&-bg {
    //  position: absolute;
    //  top: 0;
    //  left: 0;
    //  width: 70px;
    //  height: 70px;
    //  border: 20px solid #dfdcd5;
    //  border-radius: 50%;
    //}
    &-progress {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    &-svg-path-bg {
      color: #dfdcd5;
      fill: none;
      stroke: currentColor;
      stroke-width: 20px;
    }
    &-svg-path {
      color: $color-text__accent;
      opacity: 0;
      transition: all 4s ease-out;
      fill: none;
      stroke: currentColor;
      stroke-width: 20px;
      stroke-dasharray: 500;
      stroke-dashoffset: 500;
    }
    &-content {
      @include center();
      color: #243994;
      text-align: center;
      font-weight: $font-weight__medium;
      font-size: 13px;
    }
  }
}