.portfolio {
  &__slider {
    &-current {
      &__text {
        color: $color-text__accent2;
        font-weight: $font-weight__medium;
      }
      &__link {
        background: $color-text__accent;
        color: $color-text__main;
        text-decoration: none;
      }
    }
    &__list {
      background: #e3e2de;
    }
    &-control {
      background: #e3e2de;
      &__link {
        &-overlay {
          background: rgba(170, 171, 183, 0.7);
        }
      }
    }
  }
  &-link__svg {
    width: 44px;
    height: 30px;
    color: $color-text__main;
    @include center(44px, 30px);
    &_file-link {
      position: static;
      margin: 0 10px -5px 0;
      width: 20px;
      height: 20px;
    }
  }
}
.opinions {
  margin: 0 auto;
  width: 70%;
  @include breakpoint($mobile) {
    width: 96%;
  }
  &__list {
    margin: 0;
    padding: 0;
    color: $color-text__main;
    list-style: none;
    font-style: italic;
    line-height: 26px;
    @include clearfix();
  }
  &__item {
    position: relative;
    float: left;
    margin: 0 0 30px;
    width: 50%;
    text-align: center;
    @include breakpoint($mobile) {
      float: none;
      width: 100%;
    }
  }
  &__photo-wrap {
    position: relative;
    overflow: hidden;
    margin: 0 auto 20px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
  &__photo {
    max-width: 100%;
  }
  &__text {
    margin: 0 2% 20px;
    text-align: left;
  }
  &__author {
    font-weight: $font-weight__bold;
  }
}
.works-about {
  background: #131a24 center no-repeat url("/assets/img/footer_bg-works.jpg");
  &-heading {
    position: relative;
    margin: 0 0 50px;
    &__svg {
      position: absolute;
      left: 50%;
      display: block;
      margin: 33px 0 0 -170px;
      max-width: 400px;
      height: 100%;
      color: $color-text__main;
      opacity: .15;
      transform: rotate(25deg);
    }
  }
  &__heading {
    margin: 0;
    padding: 70px 0 0;
    color: $color-text__main;
  }
  &-form {
    &__heading-line {
      margin: 0 0 30px;
      padding: 0;
      color: $color-text__main;
      line-height: 1.8;
      &::after {
        border-bottom: 3px solid rgba(255,255,255,.5);
      }
    }
  }
  &__form {
    position: relative;
    margin: 30px auto 110px;
    padding: 30px 20px;
    width: 340px;
    background: rgba(255,255,255,.1);
    @include breakpoint($mobile) {
      width: 80%;
    }
  }
  &__input {
    box-sizing: border-box;
    margin: 0 0 20px;
    padding: 10px 15px;
    width: 100%;
    height: 45px;
    border: none;
    background: rgba(255,255,255,.1);
    color: $color-text__main;
  }
  &__textarea {
    box-sizing: border-box;
    margin: 0 0 35px;
    padding: 10px 15px;
    width: 100%;
    height: 130px;
    border: none;
    background: rgba(255,255,255,.1);
    color: $color-text__main;
    resize: none;
  }
  &__btn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    height: 45px;
    border: none;
    background: $color-text__accent;
    color: $color-text__main;
    cursor: pointer;
    &_clear {
      right: 0;
      left: auto;
      background: none;
      opacity: .5;
    }
  }
}