.empty-footer {
  height: 185px;
  @include breakpoint($tablet) {
    height: 220px;
  }
  @include breakpoint($mobile) {
    height: 360px;
  }
}
.footer {
  position: absolute;
  left: 0;
  margin: -185px 0 0;
  width: 100%;
  height: 185px;
  color: $color-text__main;
  font-weight: $font-weight__light;
  line-height: 26px;
  @include breakpoint($tablet) {
    margin: -220px 0 0;
    height: 220px;
    font-size: 14px;
    line-height: 20px;
  }
  @include breakpoint($mobile) {
    margin: -360px 0 0;
    height: 360px;
    line-height: 24px;
  }
  &-wrap {
    overflow: hidden;
    height: 185px - 38px;
    background: rgba(28,31,41,.85);
    @include breakpoint($tablet) {
      height: 220px - 38px;
    }
    @include breakpoint($mobile) {
      height: 340px - 38px;
    }
  }
  &__wrapper {
    height: 100%;
  }
  &-col {
    height: 100%;
  }
  &-col_1 {
    width: 35%;
    @include breakpoint($tablet) {
      float: none;
      width: 100%;
      height: auto;
    }
    &__footer-column__text {
      @include breakpoint($tablet) {
        padding: 0 0 3%;
        border-bottom: 1px solid rgba(255,255,255,0.4);
        text-align: center;
      }
    }
  }
  &-col_2 {
    width: 100-35-26%;
    @include breakpoint($tablet) {
      float: left;
      width: 74%;
    }
    @include breakpoint($mobile) {
      float: none;
      width: 100%;
      height: auto;
      @include clearfix();
    }
  }
  &-col_3 {
    width: 26%;
    @include breakpoint($tablet) {
      float: right;
      text-align: right;
    }
    @include breakpoint($mobile) {
      float: none;
      width: 100%;
      height: auto;
      text-align: center;
    }
  }
  &-column {
    position: relative;
    padding: 25px;
    @include breakpoint($tablet) {
      padding: 3% 25px 0;
    }
    &_middle {
      &::before,
      &::after {
        position: absolute;
        top: 28px;
        display: block;
        width: 1px;
        height: 88px;
        border-left: 1px solid #63656c;
        content: "";
        @include breakpoint($tablet) {
          display: none;
        }
      }
      &::before {
        left: -2px;
      }
      &::after {
        right: 1px;
      }
    }
  }
  &-menu {
    @include breakpoint($mobile) {
      padding-bottom: 60px;
      border-bottom: 1px solid rgba(255,255,255,0.4);
      text-align: center;
    }
    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: center;
      font-weight: $font-weight__medium;
      line-height: 24px;
      @include clearfix();
      @include breakpoint($mobile) {
        display: table;
        width: 100%;
      }
    }
    &__item {
      float: left;
      margin: 0 40px 0 0;
      @include breakpoint($tablet) {
        margin: 0 4% 0 0;
      }
      @include breakpoint($mobile) {
        display: table-cell;
        float: none;
        margin: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    &__link {
      color: $color-text__menu;
      text-decoration: none;
      &_active,
      &:hover, &:active, &:focus {
        color: lighten($color-text__accent, 25%);
        text-decoration: underline;
      }
    }
  }
  &_fullscreen {
    $height: 60px;
    margin: -$height 0 0;
    height: $height;
    @include breakpoint($tablet) {
      margin: -$height 0 0;
      height: $height;
    }
    @include breakpoint($mobile) {
      margin: -$height 0 0;
      height: $height;
    }
  }
  &-copyright {
    padding: 6px 0 0;
    height: 32px;
    background: rgba(8,60,150,.85);
    text-align: center;
    @include breakpoint($mobile) {
      height: 52px;
    }
    &_fullscreen {
      background: none;
    }
    &__delimetr {
      padding: 0 4px;
      @include breakpoint($mobile) {
        display: none;
      }
    }
    &__sub {
      @include breakpoint($mobile) {
        display: block;
      }
    }
  }
}
.socials_lighter {
  position: relative;
  //@include breakpoint($mobile) {
  //  @include clearfix();
  //}
}
.socials__list_lighter {
  margin: 1% 0 0 -14px;
  @include breakpoint($mobile) {
    @include center();
    margin: -30px 0 0;
    min-width: 168px;
  }
}
.socials__link_lighter {
  color: #9e9fa3;
}